import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import type { MessageWorkoutBumped } from "@trainwell/features/legacy";

type Props = {
  message: MessageWorkoutBumped;
  combineBottom: boolean;
};

export function WorkoutBumpedMessage({ message, combineBottom }: Props) {
  return (
    <Box
      sx={{
        maxWidth: "300px",
        borderRadius: `12px 12px 12px ${!combineBottom ? "0px" : "12px"}`,
        overflow: "hidden",
        backgroundColor: (theme) => theme.palette.background.default,
        p: 1,
        display: "flex",
        alignItems: "center",
        border: 1,
        borderColor: "divider",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        useFlexGap
        sx={{
          whiteSpace: "pre-line",
          wordWrap: "break-word",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.backgroundTertiary.main,
              mr: 1,
              width: 20,
              height: 20,
              borderRadius: "6px",
            }}
          >
            <RedoRoundedIcon
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: 16,
              }}
            />
          </Avatar>
          <Box>
            <Typography
              variant="h6"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              Workout bumped
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          - {message.content.workout_name}
        </Typography>
      </Stack>
    </Box>
  );
}
