import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { Client } from "@trainwell/features/legacy";
import set from "lodash-es/set";
import { api } from "src/lib/trainwellApi";
import type { RootState } from "src/slices/store";
import { updateClient } from "./clientSlice";

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (trainerId: string) => {
    const response = await api.trainers.getClients(trainerId);

    return response;
  },
);

export const fetchUpcomingCalls = createAsyncThunk(
  "clients/fetchUpcomingCalls",
  async (trainerId: string) => {
    const response = await api.trainers.getUpcomingCalls(trainerId);

    return response;
  },
);

export const fetchMoreClientInfo = createAsyncThunk(
  "clients/fetchMoreClientInfo",
  async (trainerID: string) => {
    const response = await api.trainers.getMoreClientInfo(trainerID);

    return response;
  },
);

export const toggleClientTag = createAsyncThunk(
  "clients/toggleClientTag",
  async (data: { userId: string; tag: string }, { getState, dispatch }) => {
    const state = getState() as RootState;

    const index = state.clients.clients.findIndex(
      (client) => client.user_id === data.userId,
    );

    if (index === -1) {
      throw new Error("Client not found when editing tags");
    }

    const tags = [...(state.clients.clients[index].tags ?? [])];

    if (tags.includes(data.tag)) {
      const tagIndex = tags.indexOf(data.tag);

      tags.splice(tagIndex, 1);
    } else {
      tags.push(data.tag);
    }

    await dispatch(
      updateClient({
        user_id: data.userId,
        tags: tags,
      }),
    );

    return;
  },
);

export type ClientInfo = Awaited<
  ReturnType<(typeof api)["trainers"]["getMoreClientInfo"]>
>;

// Define a type for the slice state
interface ClientsState {
  clients: Client[];
  possibleTags: string[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  clientInfo: ClientInfo;
  moreClientInfoStatus: "idle" | "loading" | "succeeded" | "failed";
  upcomingCalls: Awaited<
    ReturnType<(typeof api)["trainers"]["getUpcomingCalls"]>
  >;
  upcomingCallsStatus: "idle" | "loading" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: ClientsState = {
  clients: [],
  possibleTags: [],
  status: "idle",
  error: undefined,
  clientInfo: {},
  moreClientInfoStatus: "idle",
  upcomingCalls: {},
  upcomingCallsStatus: "idle",
};

export const clientsSclice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    resetClients: () => initialState,
    updateClientInListLocal: (
      state,
      action: PayloadAction<Partial<Client> & Pick<Client, "user_id">>,
    ) => {
      const update = action.payload;

      const clientIndex = state.clients.findIndex(
        (c) => c.user_id === update.user_id,
      );

      if (clientIndex !== -1) {
        for (const [key, value] of Object.entries(update)) {
          set(state.clients[clientIndex], key, value);
        }
      }
    },
    setClientCompletedWeeks: (
      state,
      action: PayloadAction<{ userId: string; weeks: number }>,
    ) => {
      const { userId, weeks } = action.payload;

      state.clientInfo[userId].weeks = weeks;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      console.log("Redux: Got clients");
      state.status = "succeeded";
      const clients = action.payload;

      const possibleTags: string[] = [];

      for (const client of clients) {
        const clientTags = client.tags;
        if (clientTags !== undefined) {
          clientTags.forEach(function (value) {
            if (possibleTags.indexOf(value) === -1) {
              possibleTags.push(value);
            }
          });
        }
      }

      possibleTags.sort();

      state.clients = clients;
      state.possibleTags = possibleTags;
    });
    builder.addCase(fetchClients.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(fetchMoreClientInfo.pending, (state) => {
      state.moreClientInfoStatus = "loading";
    });
    builder.addCase(fetchMoreClientInfo.fulfilled, (state, action) => {
      console.log("Redux: Got more client info");
      state.moreClientInfoStatus = "succeeded";
      const response = action.payload;

      state.clientInfo = response;
    });
    builder.addCase(fetchMoreClientInfo.rejected, (state) => {
      state.moreClientInfoStatus = "failed";
    });
    builder.addCase(toggleClientTag.fulfilled, (state) => {
      const possibleTags: string[] = [];

      for (const client of state.clients) {
        const clientTags = client.tags;
        if (clientTags !== undefined) {
          clientTags.forEach(function (value) {
            if (possibleTags.indexOf(value) === -1) {
              possibleTags.push(value);
            }
          });
        }
      }

      possibleTags.sort();

      state.possibleTags = possibleTags;
    });
    builder.addCase(fetchUpcomingCalls.pending, (state) => {
      state.upcomingCallsStatus = "loading";
    });
    builder.addCase(fetchUpcomingCalls.fulfilled, (state, action) => {
      console.log("Redux: Got upcoming calls");
      state.upcomingCallsStatus = "succeeded";

      const upcomingCalls = action.payload;

      state.upcomingCalls = upcomingCalls;
    });
    builder.addCase(fetchUpcomingCalls.rejected, (state, action) => {
      state.upcomingCallsStatus = "failed";
      state.error = action.error.message;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateClientInListLocal,
  resetClients,
  setClientCompletedWeeks,
} = clientsSclice.actions;

export default clientsSclice.reducer;

export const selectAllClients = (state: RootState) => state.clients.clients;

export const selectAllVisibleClients = createSelector(
  [selectAllClients],
  (clients) => {
    return clients.filter(
      (c) =>
        !c.account.dashboard.is_hidden &&
        ["active", "past_due"].includes(c.account.membership.state),
    );
  },
);

export const selectClientLoad = createSelector(
  [selectAllVisibleClients],
  (clients) => {
    return clients.filter((c) => !c.trainer_id_interim).length;
  },
);

export const selectClientById = (state: RootState, userId: string) =>
  state.clients.clients.find((client) => client.user_id === userId);

export const selectUpcomingCallForUserId = (
  state: RootState,
  userId: string,
) =>
  state.clients.upcomingCalls ? state.clients.upcomingCalls[userId] : undefined;
